import Language from "../../Interfaces/Language";

export const ar: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://www.criteriacorp.com/candidates/resource-hub",
    expect: "What to expect on these tests",
    expectLink: "https://www.criteriacorp.com/candidates/resource-hub",
    faq: "Frequently Asked Questions",
    faqLink: "https://criteriacandidates.helpdocs.io/article/n3duaev22o-arabic",
    copyright: "حقوق الطبع والنشر محفوظة © 2024 لشركة Criteria Corp.",
    copyrightNotices: "إشعارات حقوق النشر",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/mm12qau1le-arabic",
    terms: "شروط الاستخدام",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/ml34bu4hq0-arabic-tpx-bca-dck-loa",
    privacy: "بيان الخصوصية",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/7c6vyzgnyp-arabic-tpx-bca",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/77asm0dyzk-arabic-tpx-bca-dck",
    accommodations: "الإعاقة / الترتيبات التيسيرية",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/3g1nwcvv66-arabic-tpx",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page",
    continueButton: "متابعة",
    submitAnswer: "إرسال الإجابة",
    question: "السؤال",
    divide: "/",
    selectAnswer: "يجب عليك الرد على جميع الأسئلة.",
    textToType: "النص المطلوب كتابته",
    typePassageBelow: "اكتب النص في الأسفل"
  },
  overview: {
    approx: "تقريبًا",
    minutes: "دقيقة/دقائق"
  },
  instruction: {
    eppCopyright:
      "يتضمن هذا التقييم موادًا أُعيد إنتاجها من SalesAP ©1995 وCSAP ©2002, Multi-Health Systems Inc. جميعالحقوق محفوظة جميع.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA autorské práva © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "تم إيقاف التقييم مؤقتًا",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "يرجى <b>عدم</b> مغادرة نافذة المتصفح أو النقر خارج منطقة التقييم أثناء إجراء الاختبار.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "إذا واجهت مشكلات في الاتصال بالإنترنت، يرجى ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "النقر هنا",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF:
      "https://criteriacandidates.helpdocs.io/article/n3duaev22o-arabic",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " للحصول على بعض النصائح المتعلقة باستكشاف الأخطاء وإصلاحها.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "ستؤدي مغادرة التقييم إلى <b>حظرك</b> لأسباب أمنية تتعلق بالاختبار، وهو ما قد يمنعك من إكمال التقييم. ",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "يرجى العلم أنه إذا غادرت التقييم مرتين إضافيتين، فسيؤدي ذلك إلى <b>حظرك</b> لأسباب أمنية تتعلق بالاختبار.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>إذا غادرت التقييم مرة أخرى، فسيؤدي ذلك إلى حظرك لأسباب أمنية تتعلق بالاختبار.</b>",
    BUTTON_RESUME: "استئناف"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/n3duaev22o-arabic"
    }
  }
};
