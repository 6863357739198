import { navigate } from "@reach/router";
import React, { Component } from "react";
import { TestEventContext } from "../../../../Contexts/TestEventContext";
import { GameTestAnswerData } from "../../../../Interfaces/AnswerData";
import { nodeApiURL } from "../../../../utils/constants";
import { isEventExpired } from "../../../../utils/shared";
import RobotInspectorComponent from "../GameTutorial/GameTutorialComponents/RobotInspectorComponent";

interface Props {
  questions: any;
  startingQuestion: number;
  progressToNextGame: () => void;
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}
interface State {
  startTime: number;
  questionNumber: number;
  showInterruptModal: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  pauseTimer: boolean;
  showImages: boolean;
}

export default class RobotInspector extends Component<Props, State> {
  state = {
    startTime: Date.now(),
    questionNumber: this.props.startingQuestion,
    showInterruptModal: false,
    failCounter: 0,
    didReconnectFail: false,
    pauseTimer: false,
    showImages: false
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    this.context.useLockout = true;
    this.setState({ startTime: Date.now() });

    // delay the showing of the next images by 200 ms
    setTimeout(() => {
      this.setState({ showImages: true, pauseTimer: false });
    }, 200);
  }

  doPauseTimer = () => {
    this.setState({ pauseTimer: true });
  };

  handleAnswerSubmit = (answer: string) => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      if (this.state.questionNumber > 39) {
        return;
      }
      // get the end time to be used in calculating the timeTaken
      const endTime: number = new Date().getTime();

      // set a variable to the start time for the just answered question, to be used in calculating the timeTaken
      const answeredStartTime: number = this.state.startTime;

      // reset the start time for the next question
      const newStartTime = Date.now();

      // calculate the time taken for the just answered question
      const timeTaken =
        this.state.startTime !== null ? endTime - answeredStartTime : null;

      // set the answered data that is to be sent to redis
      const itemID: string = String(
        this.props.questions[1][this.state.questionNumber].id
      );
      const answerData: GameTestAnswerData = {
        testEventId: this.context.testEventId,
        subTestId: this.context.testIdsArray[this.context.testIndex],
        gameID: 1,
        itemID,
        questionNumber: this.state.questionNumber,
        answer,
        timeTaken
      };

      if (this.state.questionNumber === 39) {
        this.sendAnswerToRedis(answerData);
        this.props.progressToNextGame();
      } else {
        this.sendAnswerToRedis(answerData);

        // delay the showing of the next images by 200 ms
        this.setState({ showImages: false, pauseTimer: true });
        setTimeout(() => {
          this.setState({ showImages: true, pauseTimer: false });
        }, 200);

        this.setState({
          questionNumber: this.state.questionNumber + 1,
          startTime: newStartTime
        });
      }
    } else {
      // if the event has expired, we need to update the error message, and navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };

  /* ---------- Utility Functions ---------- */

  // Method to submit answers to redis
  sendAnswerToRedis = async (
    answerData: GameTestAnswerData
  ): Promise<boolean> => {
    // set variable to the root DOM element, so we can remove the blur filter once connection is re-established
    const rootElement = document.getElementById("root");

    try {
      const response = await fetch(`${nodeApiURL}/sendGameTestAnswer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(answerData)
      });

      const responseData = await response.json();
      if (responseData.submitted !== "OK") {
        this.handleRedisFailure(answerData);
      } else {
        // remove the blur filter that we set on the root DOM element when showing the InterruptModal
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }

        // reset the state and move to the next question
        this.setState(state => ({
          showInterruptModal: false,
          failCounter: 0,
          pauseTimer: false
        }));
      }
    } catch (error) {
      if (this.state.failCounter < 30) {
        this.handleRedisFailure(answerData);
      } else {
        this.setState({ didReconnectFail: true, failCounter: 0 });
      }
    }
    return true;
  };

  // if sending the answer to redis does not work for whatever reason, this method handles the failure
  handleRedisFailure = (answerData: GameTestAnswerData) => {
    this.setState((state: State) => ({
      showInterruptModal: true,
      failCounter: state.failCounter + 1,
      pauseTimer: true
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (this.state.failCounter < 30) {
      // nested setTimeout calls are a more flexible alternative to setInterval
      // they allow to set the delay between the executions more precisely
      setTimeout(() => {
        setTimeout(() => this.sendAnswerToRedis(answerData), 1000);
      }, 1000);
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  // method for handling when the time runs out before an answer is submitted
  onExpiration = () => {
    const itemID: string = String(
      this.props.questions[1][this.state.questionNumber].id
    );
    const answerData: GameTestAnswerData = {
      testEventId: this.context.testEventId,
      subTestId: this.context.testIdsArray[this.context.testIndex],
      gameID: 1,
      itemID,
      questionNumber: this.state.questionNumber,
      answer: null,
      timeTaken: null
    };
    if (this.state.questionNumber === 39) {
      this.sendAnswerToRedis(answerData);
      this.props.progressToNextGame();
    } else {
      this.sendAnswerToRedis(answerData);

      // delay the showing of the next images by 200 ms
      this.setState({ showImages: false });
      setTimeout(() => {
        this.setState({ showImages: true, pauseTimer: false });
      }, 200);

      this.setState({
        questionNumber: this.state.questionNumber + 1,
        startTime: Date.now()
      });
    }
  };

  render() {
    const {
      questions,
      timeFactor,
      answerOptions,
      pressKeys,
      disconnectModalDisplayText
    } = this.props;
    return (
      <RobotInspectorComponent
        handleAnswerSubmit={this.handleAnswerSubmit}
        onExpiration={this.onExpiration}
        showImages={this.state.showImages}
        pauseTimer={this.state.pauseTimer}
        placedAtTutorial={false}
        leftRobotImage={questions[1][this.state.questionNumber].optionA}
        rightRobotImage={questions[1][this.state.questionNumber].optionB}
        questionStartTime={this.state.startTime + 475}
        doPauseTimer={this.doPauseTimer}
        showInterruptModal={this.state.showInterruptModal}
        didReconnectFail={this.state.didReconnectFail}
        timeFactor={timeFactor}
        answerOptions={answerOptions}
        pressKeys={pressKeys}
        disconnectModalDisplayText={disconnectModalDisplayText}
      />
    );
  }
}
