import React, { Component } from "react";
import { Redirect, navigate } from "@reach/router";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaShare, FaRegCheckCircle } from "react-icons/fa";
import {
  TestEventContext,
  TestEventConsumer
} from "../Contexts/TestEventContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { odaURL, apiURL } from "../utils/constants";
import PageContinueButton from "../Components/PageContinueButton";
import EventIdv2 from "./EventIdV2";
import { convertDateTimezone, getTranslatedTextWithURL } from "../utils/shared";

interface State {
  emailSent: boolean;
  expirationDate: string | null;
  emailSentLoading: boolean;
}

interface Props {
  path: string;
  retrievingData: boolean;
  updateDateEventStarted: (
    testTakerId: string,
    testEventId: string,
    token: string
  ) => void;
}
export default class EventId extends Component<Props> {
  state: State = {
    emailSent: false,
    expirationDate: null,
    emailSentLoading: false
  };
  // define the context type so that we can use this.context outside of the render method
  static contextType: any = TestEventContext;

  componentDidMount() {
    // check if candidate is coming straight here from testTaker form (already has an eventId), and
    // if so, setting the trueDateEventStarted value if it's the first time starting the event.
    if (
      this.context.testEventData.eventStarted === 0 &&
      this.context.testEventData.testEventId
    ) {
      this.props.updateDateEventStarted(
        this.context.testEventData.testTaker.testTakerId,
        this.context.testEventData.testEventId,
        this.context.testEventData.token
      );
    }
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });

    if (this.context.eventExpirationDate) {
      const isAULocale = this.context
        ? this.context.testEventData.locale === "AU"
        : null;

      const formattedDate = convertDateTimezone(
        this.context.eventExpirationDate,
        this.context.eventInvitationTimeZone,
        isAULocale,
        this.context.testEventData.translatedText.language
      );

      formattedDate.then(response => {
        this.setState({
          expirationDate: response
        });
      });
    }
  }

  /* ---------- Event Handlers ----------- */

  handleContinue = () => {
    navigate("/overview");
  };

  handleEmailEventId = () => {
    this.setState({ emailSentLoading: true });
    this.sendEmail(this.context.eventId);
    setTimeout(() => {
      this.setState({ emailSentLoading: false, emailSent: true });
    }, 2000);
  };

  sendEmail = async (eventId: string): Promise<boolean> => {
    try {
      const showNewEventIdPage =
        this.context.testEventData.translatedText.language === "english" &&
        this.context.configCatObject.isABTestEventIdEnabled;

      const eventCopyToUse = showNewEventIdPage ? "eventCopyNew" : "eventCopy";

      const response = await fetch(
        `${apiURL}/${eventCopyToUse}/${eventId}/${this.context.testEventData.translatedText.language}`,
        {
          method: "POST",
          headers: {
            Authorization: this.context.token
          }
        }
      );
      const data = await response.json();

      if (!data.error) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  render() {
    return (
      <TestEventConsumer>
        {context => {
          if (context && Object.keys(context.testEventData).length > 0) {
            const landingPageStyle = context.generateLandingPageStyle(
              context.testEventData.landingPage
            );

            const isABTestEventIdEnabled =
              context.configCatObject?.isABTestEventIdEnabled;

            const {
              yourEventId,
              uniqueIdentifier,
              eventIdPurpose,
              eventIdEmailSuccess,
              emailEventId
            } = context.testEventData.translatedText;

            const showNewEventIdPage =
              isABTestEventIdEnabled &&
              context.testEventData.translatedText.language === "english";

            const isArabic = context?.testEventData?.translatedText?.locale
              ? context.testEventData.translatedText?.locale === "AR"
              : null;

            return (
              <Container>
                <Row>
                  <Col
                    xl={{ span: 8, offset: 2 }}
                    lg={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                    sm={12}
                  >
                    <Card
                      className={
                        showNewEventIdPage
                          ? "oda-card-new-eventid-page"
                          : "oda-card"
                      }
                    >
                      <Header
                        onWelcomePage={false}
                        backgroundColor={landingPageStyle.backgroundColor}
                        customLogo={context?.testEventData.logo}
                        isVideoInterviewDiscoveryJob={
                          context?.isVideoInterviewDiscoveryJob
                        }
                      />
                      {showNewEventIdPage ? (
                        <EventIdv2
                          landingPageStyle={landingPageStyle}
                          eventId={context.eventId}
                          handleEmailEventId={this.handleEmailEventId}
                          emailSent={this.state.emailSent}
                          emailSentLoading={this.state.emailSentLoading}
                          yourEventId={yourEventId}
                          uniqueIdentifier={uniqueIdentifier}
                          eventIdPurpose={eventIdPurpose}
                          eventIdEmailSuccess={eventIdEmailSuccess}
                          emailEventId={emailEventId}
                          isArabic={isArabic}
                        />
                      ) : (
                        <Card.Body
                          style={{
                            backgroundColor: landingPageStyle.backgroundColor,
                            color: landingPageStyle.textColor
                          }}
                          className={isArabic ? "text-right" : ""}
                          dir={isArabic ? "rtl" : "ltr"}
                        >
                          <p>
                            {context.testEventData.translatedText
                              ? context.testEventData.translatedText.writeDown
                              : "Please write down the following Event ID for your records"}
                          </p>
                          <p id="lead">
                            {context.testEventData.translatedText
                              ? context.testEventData.translatedText.eventId
                              : "Event ID"}
                            :{" "}
                            <span
                              style={{
                                textTransform:
                                  context?.eventId?.length === 11
                                    ? "uppercase"
                                    : "none" // the new eventId with 16 characters is case-sensitive while the 11-char old eventId is not. Thus only transform the 11-char eventId text to uppercase.
                              }}
                            >
                              {`${context?.eventId?.slice(
                                0,
                                3
                              )}-${context?.eventId?.slice(
                                3,
                                7
                              )}-${context?.eventId?.slice(7, 11)}`}
                              {context?.eventId?.length === 11
                                ? null
                                : `-${context?.eventId?.slice(11)}`}
                            </span>
                          </p>
                          {!this.context.testEventData.testTaker
                            .testTakerEmail ? null : (
                            <Row className="mb-3">
                              <Col
                                xl={{ span: 8, offset: 2 }}
                                lg={{ span: 8, offset: 2 }}
                                md={{ span: 10, offset: 1 }}
                              >
                                <Button
                                  block={true}
                                  variant="outline-secondary"
                                  className={isArabic ? "text-right" : ""}
                                  onClick={this.handleEmailEventId}
                                >
                                  <span className={isArabic ? "mr-2" : ""}>
                                    {this.state.emailSent
                                      ? `${this.context.testEventData.translatedText.sentTo} ${this.context.testEventData.testTaker.testTakerEmail}`
                                      : this.context.testEventData
                                          .translatedText.emailEventId}
                                  </span>
                                  {this.state.emailSent ? (
                                    <FaRegCheckCircle
                                      className="float-right mt-1"
                                      style={{ color: "#00c067" }}
                                    />
                                  ) : (
                                    <FaShare className="float-right mt-1" />
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          )}
                          {context.testEventData.translatedText ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: getTranslatedTextWithURL(
                                  this.context,
                                  "connectionIssue"
                                )
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <p>
                                For an optimal experience, please ensure your
                                device has a reliable internet connection.
                              </p>
                              <p>
                                If you experience an Internet connection issue
                                and need to restart your assessment, go to
                                <a
                                  href={odaURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  www.oda1.com
                                </a>
                                , and enter in the Event ID above.
                              </p>
                            </React.Fragment>
                          )}
                          {this.state.expirationDate ? (
                            <span>
                              {context.testEventData.translatedText ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      context.testEventData.translatedText
                                        .eventIdValidUntil
                                  }}
                                />
                              ) : null}
                              {` `}
                              <strong>{this.state.expirationDate}</strong>.
                            </span>
                          ) : null}
                        </Card.Body>
                      )}
                      <Card.Footer
                        style={{
                          borderTop: 0,
                          backgroundColor: landingPageStyle.backgroundColor
                        }}
                        className={showNewEventIdPage ? "py-4" : ""}
                      >
                        <PageContinueButton
                          handler={this.handleContinue}
                          buttonText={
                            context && context.testEventData.translatedText
                              ? context.testEventData.translatedText
                                  .continueButton
                              : "Continue"
                          }
                          showNewEventIdPage={showNewEventIdPage}
                        />
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
                <Footer />
              </Container>
            );
          }
          // If the user has arrived here without any way of fetching the testEventData, redirect back to the
          // eventIdForm
          return <Redirect from="verify" to="/" noThrow={true} />;
        }}
      </TestEventConsumer>
    );
  }
}
