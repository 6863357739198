import Language from "../../Interfaces/Language";

export const es_es: Language = {
  common: {
    help: "¿Necesita ayuda?",
    helpCenter: "Servicio de ayuda",
    expect: "¿Cómo son las pruebas?",
    faq: "Preguntas frecuentes",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/oogy0dpl3l-preguntas-frecuentes-spanish-spain",
    copyright: "Derechos de autor",
    copyrightNotices: "Avisos de derechos de autor",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/xoygvq4v6d-aviso-de-derechos-de-autor-spanish-spain",
    terms: "Términos de uso",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/8z4l2emrb9-t-rminos-de-uso-spanish-spain",
    privacy: "Declaración de privacidad",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/e42bsbl0ao-pol-tica-de-privacidad-spanish-spain",
    dmca: "Política DMCA",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/abu19mhj8z-pol-tica-dmca-spanish-spain",
    accommodations: "Discapacidad/Adaptaciones",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/awuq7hx7b3-discapacidad-adaptaciones-spanish-spain",
    technicalSupport: "Soporte técnico",
    chatSupport:
      "Si tiene alguna pregunta, chatee con nuestro equipo en la parte inferior derecha de esta página.",
    continueButton: "Continuar",
    submitAnswer: "Enviar respuesta",
    question: "Pregunta",
    divide: "/",
    selectAnswer: "Debes responder a la pregunta para continuar.",
    textToType: "Texto a Escribir",
    typePassageBelow: "Escribe el Texto a Continuación"
  },
  overview: {
    minutes: "min",
    approx: "aprox."
  },
  instruction: {
    eppCopyright:
      "Esta evaluación incluye material reproducido de SalesAP ©1995 y CSAP ©2002, Multi-Health Systems Inc. Todos los derechos reservados.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "Copyright WTMA © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Su evaluación se ha pausado.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Por favor, <b>no</b> salga de la ventana del navegador ni haga clic fuera del área de la evaluación mientras dure la prueba.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Si experimenta problemas de conectividad a Internet, haga ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "clic aquí",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF:
      "https://criteriacandidates.helpdocs.io/article/oogy0dpl3l-preguntas-frecuentes-spanish-spain",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " para obtener algunos consejos de solución de problemas.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Si sale de la evaluación podría <b>no poder volver a acceder</b> por motivos de la seguridad de la prueba, lo cual podría impedirle completar su evaluación.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Si sale de la evaluación <b>dos veces más</b>, le será <b>imposible volver a acceder</b> por motivos de seguridad de la prueba.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Si vuelve a salir de la evaluación, no podrá volver a acceder por motivos de seguridad de la prueba.</b>",
    BUTTON_RESUME: "Reanudar"
  },
  error: {
    common: {
      UH_OH: "¡Vaya!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Error al cargar la página por problemas en la red. Compruebe su conexión a Internet y actualice la página en unos instantes.",
      MESSAGE_PARAGRAPH_2_SECTION_1: "Si el problema persiste, consulte las ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "preguntas frecuentes sobre asistencia",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/oogy0dpl3l-preguntas-frecuentes-spanish-spain"
    }
  }
};
