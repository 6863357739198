import { navigate } from "@reach/router";
import React, { Component } from "react";
import { TestEventContext } from "../../../../Contexts/TestEventContext";
import { GameTestAnswerData } from "../../../../Interfaces/AnswerData";
import { nodeApiURL } from "../../../../utils/constants";
import { isEventExpired } from "../../../../utils/shared";
import BirdFeatherComponent from "../GameTutorial/GameTutorialComponents/BirdFeatherComponent";

interface Props {
  progressToNextGame: () => void;
  startingQuestion: number;
  initialQuestionData: {
    questionId: string;
    optionA: string;
    optionB: string;
  };
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
  testQuestions: {
    [propName: string]: {
      testQuestionId: number;
      optionA: string;
      optionB: string;
    };
  };
}
interface State {
  startTime: number;
  questionNumber: number;
  showInterruptModal: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  pauseTimer: boolean;
  showImages: boolean;
  questionId: string;
  optionA: string;
  optionB: string;
}

export default class BirdFeather extends Component<Props, State> {
  state = {
    startTime: Date.now(),
    questionNumber: this.props.startingQuestion,
    showInterruptModal: false,
    failCounter: 0,
    didReconnectFail: false,
    pauseTimer: false,
    showImages: false,
    questionId: this.props.initialQuestionData.questionId,
    optionA:
      this.props.testQuestions[this.props.initialQuestionData.questionId]
        ?.optionA,
    optionB:
      this.props.testQuestions[this.props.initialQuestionData.questionId]
        ?.optionB
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    this.context.useLockout = true;
    this.setState({ startTime: Date.now() });

    // delay the showing of the next images by 200 ms
    setTimeout(() => {
      this.setState({ showImages: true, pauseTimer: false });
    }, 200);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.initialQuestionData.questionId !==
      this.props.initialQuestionData.questionId
    ) {
      this.setState({
        questionId: this.props.initialQuestionData.questionId,
        optionA:
          this.props.testQuestions[this.props.initialQuestionData.questionId]
            .optionA,
        optionB:
          this.props.testQuestions[this.props.initialQuestionData.questionId]
            .optionB
      });
    }
  }

  /* ---------- Event Handlers ---------- */

  doPauseTimer = () => {
    this.setState({ pauseTimer: true });
  };

  handleAnswerSubmit = (answer: string) => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      if (this.state.questionNumber > 39) {
        return;
      }
      // get the end time to be used in calculating the timeTaken
      const endTime: number = new Date().getTime();

      // set a variable to the start time for the just answered question, to be used in calculating the timeTaken
      const answeredStartTime: number = this.state.startTime;

      // calculate the time taken for the just answered question
      const timeTaken =
        this.state.startTime !== null ? endTime - answeredStartTime : null;

      const itemID: string = this.state.questionId;
      const answerData: GameTestAnswerData = {
        testEventId: this.context.testEventId,
        subTestId: this.context.testIdsArray[this.context.testIndex],
        gameID: 2,
        itemID,
        questionNumber: this.state.questionNumber,
        answer,
        timeTaken
      };
      this.sendAnswerToRedis(answerData, this.state.questionNumber + 1);
      if (this.state.questionNumber === 39) {
        this.props.progressToNextGame();
        this.setState({
          questionNumber: this.state.questionNumber + 1
        });
      } else {
        // delay the showing of the next images till the next question is returned
        this.setState({ showImages: false, pauseTimer: true });
      }
    } else {
      // if the event has expired, we need to update the error message, and navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };

  // Method to submit answers to redis
  sendAnswerToRedis = async (
    answerData: GameTestAnswerData,
    nextQuestionNumber: number
  ): Promise<boolean> => {
    // set variable to the root DOM element, so we can remove the blur filter once connection is re-established
    const rootElement = document.getElementById("root");

    try {
      const response = await fetch(`${nodeApiURL}/getNextAdaptiveQuestion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(answerData)
      });

      const responseData = await response.json();

      // remove the blur filter that we set on the root DOM element when showing the InterruptModal
      if (rootElement !== null) {
        rootElement.style.filter = "none";
      }

      // reset the state and move to the next question
      this.setState(state => ({
        showInterruptModal: false,
        failCounter: 0,
        showImages: true,
        pauseTimer: false,
        questionNumber: nextQuestionNumber,
        startTime: Date.now(),
        questionId: responseData.nextQuestion.questionId,
        optionA:
          this.props.testQuestions[responseData.nextQuestion.questionId]
            .optionA,
        optionB:
          this.props.testQuestions[responseData.nextQuestion.questionId].optionB
      }));
    } catch (error) {
      if (this.state.failCounter < 30) {
        this.handleRedisFailure(answerData, nextQuestionNumber);
      } else {
        this.setState({
          didReconnectFail: true,
          failCounter: 0
        });
      }
    }
    return true;
  };

  // if sending the answer to redis does not work for whatever reason, this method handles the failure
  handleRedisFailure = (
    answerData: GameTestAnswerData,
    nextQuestionNumber: number
  ) => {
    this.setState((state: State) => ({
      showInterruptModal: true,
      failCounter: state.failCounter + 1,
      pauseTimer: true
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (this.state.failCounter < 30) {
      setTimeout(() => {
        setTimeout(
          () => this.sendAnswerToRedis(answerData, nextQuestionNumber),
          1000
        );
      }, 1000);
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  // method for handling when the time runs out before an answer is submitted
  onExpiration = () => {
    const itemID: string = this.state.questionId;
    const answerData: GameTestAnswerData = {
      testEventId: this.context.testEventId,
      subTestId: this.context.testIdsArray[this.context.testIndex],
      gameID: 2,
      itemID,
      questionNumber: this.state.questionNumber,
      answer: null,
      timeTaken: null
    };
    this.sendAnswerToRedis(answerData, this.state.questionNumber + 1);
    if (this.state.questionNumber === 39) {
      this.props.progressToNextGame();
    } else {
      // delay the showing of the next images till the next question is returned
      this.setState({ showImages: false, pauseTimer: true });
    }
  };

  render() {
    const { timeFactor, answerOptions, pressKeys, disconnectModalDisplayText } =
      this.props;
    return (
      <BirdFeatherComponent
        handleAnswerSubmit={this.handleAnswerSubmit}
        onExpiration={this.onExpiration}
        showImages={this.state.showImages}
        pauseTimer={this.state.pauseTimer}
        placedAtTutorial={false}
        optionA={this.state.optionA}
        optionB={this.state.optionB}
        questionStartTime={this.state.startTime + 475}
        doPauseTimer={this.doPauseTimer}
        showInterruptModal={this.state.showInterruptModal}
        didReconnectFail={this.state.didReconnectFail}
        timeFactor={timeFactor}
        answerOptions={answerOptions}
        pressKeys={pressKeys}
        disconnectModalDisplayText={disconnectModalDisplayText}
      />
    );
  }
}
