import Language from "../../Interfaces/Language";

export const zh_cn: Language = {
  common: {
    help: "需要帮助？",
    helpCenter: "帮助中心",
    expect: "在这些测试中会出现什么情况？",
    faq: "常见问题",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/2qnkzd946q-chinese-simplified-mqi",
    copyright: "版权所有",
    copyrightNotices: "版权声明",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/2dqbx8qj81-chinese-simplified-hwm-gnl",
    terms: "使用条款",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/qw7s8yyi9q-chinese-simplified",
    privacy: "隐私声明",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/8sblk0ua4i-chinese-simplified-hwm",
    dmca: "DMCA政策",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/9d2pchluob-chinese-simplified-mqi-zvw",
    accommodations: "残障人士/便利设施",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/mzotipylmo-chinese-simplified",
    technicalSupport: "技术支持",
    chatSupport: "如需支持，请在本页右下角与我们的团队聊天。",
    continueButton: "继续",
    submitAnswer: "提交答案",
    question: "",
    divide: "",
    selectAnswer: "您必须回答该问题才能继续。",
    textToType: "要输入的文本",
    typePassageBelow: "请在下方输入文本"
  },
  overview: {
    minutes: "分钟",
    approx: "大约"
  },
  instruction: {
    eppCopyright:
      "本评估包含了来自 SalesAP ©1995 和 CSAP ©2002 的复制材料，Multi-Health Systems Inc. 保留所有权利。",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "您的评估已暂停。",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "在测试进行期间，请<b>不要</b>离开您的浏览器窗口或在评估区域之外点击。",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "如果您遇到互联网连接问题，请",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "点击此处",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF:
      "https://criteriacandidates.helpdocs.io/article/2qnkzd946q-chinese-simplified-mqi",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2: "获取一些故障排除提示。",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "为测试安全起见，离开评估会造成您被<b>锁定</b>，这可能会阻止您完成评估。",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "为测试安全起见，如果您再离开评估<b>两次</b>，您将被<b>锁定</b>。",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>为测试安全起见，如果您再次离开评估，您的页面将被锁定。</b>",
    BUTTON_RESUME: "恢复"
  },
  error: {
    common: {
      UH_OH: "啊哦！"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "由于网络问题，无法加载。请检查您的互联网连接，稍后再刷新页面。",
      MESSAGE_PARAGRAPH_2_SECTION_1: "如果问题依旧，请查看我们的",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "支持常见问题解答",
      MESSAGE_PARAGRAPH_2_SECTION_2: "。",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/2qnkzd946q-chinese-simplified-mqi"
    }
  }
};
