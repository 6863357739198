import Language from "../../Interfaces/Language";

export const cs: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://www.criteriacorp.com/candidates/resource-hub",
    expect: "What to expect on these tests",
    expectLink: "https://www.criteriacorp.com/candidates/resource-hub",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/h1056qrqlq-odpov-di-na-ast-dotazy-czech",
    copyright: "Autorská práva",
    copyrightNotices: "Informace o autorských právech",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/tfnudrnz4a-informace-o-autorsk-ch-pr-vech-czech",
    terms: "Podmínky užití",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/18gnoa2h5m-podm-nky-u-it-czech",
    privacy: "Prohlášení o ochraně osobních údajů",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/gbanhg93m4-prohl-en-o-ochran-osobn-ch-daj-czech",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/fq7revz08l-z-sady-dmca-czech",
    accommodations: "Postižení / úlevy",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/8dy9j4m513-posti-en-levy-czech",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page",
    continueButton: "Pokračovat",
    submitAnswer: "Odeslat odpověď",
    question: "Otázka",
    divide: "/",
    selectAnswer: "Chcete-li pokračovat, musíte odpovědět na otázku. ",
    textToType: "Text k opisu",
    typePassageBelow: "Opisujte text níže"
  },
  instruction: {
    eppCopyright:
      "Toto hodnocení zahrnuje materiál reprodukovaný z SalesAP ©1995 a CSAP ©2002, Multi-Health Systems Inc. Všechna práva vyhrazena.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "Autorská práva WTMA © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "cca.",
    minutes: "min"
  },
  //
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Vaše testování bylo pozastaveno.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "V průběhu testování <b>neopouštějte</b>okno prohlížeče ani neklikejte mimo hodnocenou oblast.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Pokud máte problémy s připojením k internetu, po ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "kliknutí sem",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF:
      "https://criteriacandidates.helpdocs.io/article/h1056qrqlq-odpov-di-na-ast-dotazy-czech",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " se Vám zobrazí tipy jak problém odstranit.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "V opačném případě dojde z bezpečnostních důvodů k <b>zablokování</b> testu, což Vám může zabránit v jeho dokončení.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Vezměte prosím na vědomí, že pokud z testování ještě 2krát vystoupíte, dojde z bezpečnostních důvodů k jeho <b>zablokování</b>.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Pokud z testování znovu vystoupíte, dojde z bezpečnostních důvodů k jeho zablokování.</b>",
    BUTTON_RESUME: "Pokračovat"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/h1056qrqlq-odpov-di-na-ast-dotazy-czech"
    }
  }
};
