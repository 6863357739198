import React, { FunctionComponent, useState } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import GameTest from "../../Tests/TestDisplays/GameTest/GameTest";
import GameTutorial from "../../Tests/TestDisplays/GameTest/GameTutorial/GameTutorial";
import { getGameQuestionAndStartingId } from "../../utils/redisHelpers";
import NetworkError from "../utility/error/network/NetworkError";
import Loader from "../utility/loader/Loader";

const GameKey = {
  1: "game-test-robot",
  2: "game-test-bird",
  3: "game-test-weigh"
} as const;

const Game: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getGameQuestionAndStartingId>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });
  const [isTutorial, setIsTutorial] = useState(true);

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  const gameStartHandler = () => {
    setIsTutorial(current => !current);
  };

  const gameResetHandler = () => {
    data.question = 0;
    data.taskId += 1;
  };

  if (isTutorial && data.question > 0) {
    setIsTutorial(false);
    return <Loader />;
  }

  if (isTutorial) {
    return (
      <GameTutorial
        testData={props.testData}
        timeFactor={props.timeFactor}
        gameTutorialId={data.taskId}
        handleStartRealTest={gameStartHandler}
      />
    );
  }

  return (
    <GameTest
      key={GameKey[data.taskId as keyof typeof GameKey]}
      {...props}
      startingQuestion={data.question}
      currentGameId={data.taskId}
      forceGameStart
      resetStartingQuestion={gameResetHandler}
    />
  );
};

export default Game;
