/* eslint-disable eqeqeq */
// tslint:disable triple-equals
import { navigate } from "@reach/router";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  Row
} from "react-bootstrap";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { MdSignalWifiOff } from "react-icons/md";
import Lightbox from "react-image-lightbox";
import { BarLoader } from "react-spinners";
import ContinuousTimer from "../../Components/ContinuousTimer";
import InterruptModal from "../../Components/InterruptModal";
import NextButton from "../../Components/NextButton";
import ProgressBar from "../../Components/ProgressBar";
import { TestEventContext } from "../../Contexts/TestEventContext";
import { TestMakerAnswerData } from "../../Interfaces/AnswerData";
import { nodeApiURL } from "../../utils/constants";
import { isEventExpired } from "../../utils/shared";

import "react-image-lightbox/style.css";
import { translate } from "../../utils/translationHelpers";

interface State {
  questionNumber: number;
  testMakerTestQuestionId: number;
  answer: string;
  timeStarted: Date | null;
  timeTaken: number | null;
  error: string | null;
  showInterruptModal: boolean;
  pauseTimer: boolean;
  failCounter: number;
  answeredArray: string[];
  timeRemaining: number;
  didReconnectFail: boolean;
  specificQuestionChosen: boolean;
  individualQuestionExpiredTime: number;
  disableNextButton: boolean;
  imageIsOpen: boolean;
  images: any;
  timeTakenRecorded: boolean;
}
interface Props {
  testData: any;
  testMakerTestId: number;
  startingQuestion: number;
  timeRemaining: number;
  dataLoaded: boolean;
  answeredArray: string[];
  endSubTest: () => void;
  timeFactor: number;
  language: string;
}

// Determine the subdomain to get the correct S3 bucket name and show the image correctly
// This should be conditioned on the Instance, not the Locale
const hostnameArray = window.location.hostname.split(".");
const subdomain = hostnameArray[0].toUpperCase();
const s3BucketName =
  subdomain === "AU"
    ? "criteria-corp-object-store-au"
    : "criteria-corp-object-store";

const s3Region = subdomain === "AU" ? "ap-southeast-2" : "us-east-1";

export default class TestMakerTest extends Component<Props, State> {
  state: State = {
    questionNumber: 0,
    testMakerTestQuestionId: this.props.testData.questions[0].id, // set equal to the id value of the first question
    answer: "",
    timeStarted: null,
    timeTaken: null,
    error: null,
    showInterruptModal: false,
    pauseTimer: false,
    failCounter: 0,
    didReconnectFail: false,
    answeredArray: this.props.answeredArray,
    timeRemaining: this.props.timeRemaining,
    individualQuestionExpiredTime: this.props.timeRemaining,
    specificQuestionChosen: false,
    disableNextButton: false,
    imageIsOpen: false,
    images: [],
    timeTakenRecorded: false // has the time been recorded in Redis. this is to prevent calculating the timeTaken twice during sendAnswerFromRedis and getAnswerFromRedis
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  /* ---------- React LifeCycle Methods ---------- */

  componentDidMount() {
    this.setState({ timeStarted: new Date() });
    const images: any[] = [];
    this.props.testData.questions.forEach((question: any) => {
      if (question.image) {
        let image = document.createElement("img");
        image.src = `https://${s3BucketName}.s3.${s3Region}.amazonaws.com/hireselect/userfiles/${question.image}`;
        const poll = setInterval(() => {
          if (image.naturalWidth) {
            clearInterval(poll);
            images.push({ id: question.id, width: image.naturalWidth });
            this.setState({ images });
          }
        }, 10);
      }
    });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.startingQuestion > 0 && prevState.questionNumber === 0) {
      return {
        questionNumber: prevState.specificQuestionChosen
          ? prevState.questionNumber
          : nextProps.startingQuestion,
        testMakerTestQuestionId: !prevState.specificQuestionChosen
          ? nextProps.testData.questions[nextProps.startingQuestion].id
          : nextProps.testData.questions[prevState.questionNumber].id
      };
    }

    // this is required for individually timed questions, so that when a new question is displayed, it will not
    // use the props-defined expiredTime (since that is a static value in this method).  instead, it resets the
    // time to be the full timeLimit of the new question.
    if (
      (nextProps.testData.timeQuestions &&
        nextProps.timeRemaining ===
          nextProps.testData.questions[prevState.questionNumber].timeLimit *
            nextProps.timeFactor) ||
      prevState.questionNumber === 0
    ) {
      return {
        individualQuestionExpiredTime:
          nextProps.testData.questions[prevState.questionNumber].timeLimit *
          nextProps.timeFactor
      };
    }
    return {};
  }

  /* ---------- Event Handlers ---------- */
  calculateTimeTaken = (calculateForEssaySpace: boolean) => {
    // calculate the time taken to answer the question
    const newTime: Date = new Date();

    // convert timeStarted and newTime to milliseconds and find the difference, then convert back to seconds
    const timeStartedInMilliseconds =
      this.state.timeStarted !== null ? this.state.timeStarted.getTime() : 0;
    const newTimeInMilliseconds = newTime.getTime();

    // since BE adds each question's timeTaken value to get the totalTimeTaken value
    // we will use Math.floor here to minimize the gap between the elapsed time counted in the ContinuousTimer and the takenTime calculated here
    // to guarantee the sum of the time recorded in redis doesn't go over the time limit
    // however, if we're calculating for hitting the space bar for the essay questions
    // we will use Math.round to hopefully "average out" the minor differences in the time.
    const timeTaken = calculateForEssaySpace
      ? Math.round((newTimeInMilliseconds - timeStartedInMilliseconds) / 1000)
      : Math.floor((newTimeInMilliseconds - timeStartedInMilliseconds) / 1000);
    return timeTaken;
  };

  handleNext = () => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      const timeTaken = this.state.timeTakenRecorded
        ? 0
        : this.calculateTimeTaken(false);
      // scroll to top of the screen
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });

      // set the answerData object that we then send to redis to record data for each answer
      if (this.state.answer) {
        let answerData: TestMakerAnswerData;

        // if this is the first time through (no internet connection interrupt), we need to set the timeTaken in state
        // prior to creating the answerData object and sending it to redis.  if this is a subsequent try due to
        // connection issues, the timeTaken is already set and we can skip the setState part.  this is primarily
        // to avoid small increases in the timeTaken for each second that reconnect is attempted.
        if (this.state.timeTaken === null) {
          this.setState({ timeTaken, timeTakenRecorded: true }, () => {
            answerData = {
              testEventId: this.context.testEventId,
              testMakerTestId: this.props.testMakerTestId,
              testMakerTestQuestionId: this.state.testMakerTestQuestionId,
              questionNumber: this.state.questionNumber,
              answer: this.state.answer,
              timeTaken:
                this.state.timeTaken !== null ? this.state.timeTaken : 0
            };
            this.sendAnswerToRedis(answerData, true, this.context.token);
          });
        } else {
          answerData = {
            testEventId: this.context.testEventId,
            testMakerTestId: this.props.testMakerTestId,
            testMakerTestQuestionId: this.state.testMakerTestQuestionId,
            questionNumber: this.state.questionNumber + 1,
            answer: this.state.answer,
            timeTaken: this.state.timeTaken !== null ? this.state.timeTaken : 0
          };
          this.sendAnswerToRedis(answerData, true, this.context.token);
        }

        // add answered questionId to the answeredArray in state
        const newAnsweredArray = [
          ...this.state.answeredArray,
          String(this.state.testMakerTestQuestionId)
        ];
        this.setState({ answeredArray: newAnsweredArray });

        // if it is a timeQuestions (individually-timed) question, reset time remaining to the next questions time limit
        // and reset the expiredTime in redis, as well as the local state variable individualQuestionExpiredTime, to 0
        if (
          this.props.testData.timeQuestions &&
          this.props.testData.questions[this.state.questionNumber + 1]
        ) {
          this.setState({
            timeRemaining:
              this.props.testData.questions[this.state.questionNumber + 1]
                .timeLimit * this.props.timeFactor,
            individualQuestionExpiredTime:
              this.props.testData.questions[this.state.questionNumber + 1]
                .timeLimit * this.props.timeFactor
          });
        }
        // reset no answer warning if an answer is selected
        if (this.state.error !== null) {
          this.setState({ error: null, timeTaken: null });
        }
      } else {
        const language = this.props.language;
        this.setState({ error: translate("common", "selectAnswer", language) });
      }
    } else {
      // if the event has expired, we need to update the error message, and navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };

  handleAnswerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ answer: event.target.value }, () => {
      if (this.state.error !== null) {
        this.setState({ error: null });
      }
    });
  };

  handleCheckAnswerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let answersArray: string[];
    answersArray = [];
    if (this.state.answer) {
      answersArray = this.state.answer.split(",");
    }
    if (event.target.checked) {
      answersArray.push(event.target.value);
    } else {
      const index = answersArray.indexOf(event.target.value);
      answersArray.splice(index, 1);
    }
    answersArray.sort();
    this.setState({ answer: answersArray.join(",") }, () => {
      if (this.state.error !== null) {
        this.setState({ error: null });
      }
    });
  };

  handleEssayEntry = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === " ") {
      const timeTaken = this.state.timeTakenRecorded
        ? 0
        : this.calculateTimeTaken(true);
      let answerData: TestMakerAnswerData;
      answerData = {
        testEventId: this.context.testEventId,
        testMakerTestId: this.props.testMakerTestId,
        testMakerTestQuestionId: this.state.testMakerTestQuestionId,
        questionNumber: this.state.questionNumber + 1,
        answer: this.state.answer,
        timeTaken
      };
      this.sendAnswerToRedis(answerData, false, this.context.token);
    }
  };

  handleEssayEntryText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ answer: event.target.value }, () => {
      if (this.state.error !== null) {
        this.setState({ error: null });
      }
    });
  };

  handleIndividualTimeExpiration = () => {
    // scroll to top of the screen
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });

    // set the answerData object that we then send to redis to record data for each answer
    const answerData: TestMakerAnswerData = {
      testEventId: this.context.testEventId,
      testMakerTestId: this.props.testMakerTestId,
      testMakerTestQuestionId: this.state.testMakerTestQuestionId,
      questionNumber: this.state.questionNumber + 1,
      answer: this.state.answer,
      timeTaken:
        this.props.testData.questions[this.state.questionNumber].timeLimit *
        this.props.timeFactor
    };

    this.sendAnswerToRedis(answerData, true, this.context.token);

    // add answered questionId to the answeredArray in state
    const newAnsweredArray = [
      ...this.state.answeredArray,
      String(this.state.testMakerTestQuestionId)
    ];
    this.setState({ answeredArray: newAnsweredArray });

    // if it is a timeQuestions (individually-timed) question, reset time remaining to the next questions time limit
    // and reset the expiredTime in redis, as well as the local state variable individualQuestionExpiredTime, to 0
    if (
      this.props.testData.timeQuestions &&
      this.props.testData.questions[this.state.questionNumber + 1]
    ) {
      this.setState({
        timeRemaining:
          this.props.testData.questions[this.state.questionNumber + 1]
            .timeLimit * this.props.timeFactor,
        individualQuestionExpiredTime:
          this.props.testData.questions[this.state.questionNumber + 1]
            .timeLimit * this.props.timeFactor
      });
    }

    // reset no answer warning if an answer is selected
    if (this.state.error !== null) {
      this.setState({ error: null, timeTaken: null });
    }
  };

  handlePageNavigation = (event: any) => {
    const clickedQuestionNumber = parseInt(
      event.currentTarget.dataset.question,
      10
    );
    const currentTestQuestionId = this.state.testMakerTestQuestionId;
    const targetTestQuestionId =
      this.props.testData.questions[clickedQuestionNumber].id;

    this.setState(
      {
        questionNumber: clickedQuestionNumber,
        specificQuestionChosen: true
      },
      () => {
        this.getAnswerFromRedis(
          this.state.questionNumber,
          this.context.token,
          currentTestQuestionId,
          targetTestQuestionId
        );
      }
    );
  };

  handleRetryButton = () => {
    this.setState({ didReconnectFail: false }, () => {
      this.handleRedisFailure();
    });
  };

  handleEnlarge = () => {
    this.setState({ imageIsOpen: true });
  };

  handleEnlargeClose = () => {
    this.setState({ imageIsOpen: false });
  };

  handleRedisFailure = () => {
    this.setState(state => ({
      showInterruptModal: true,
      pauseTimer: true,
      failCounter: state.failCounter + 1
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (this.state.failCounter < 30) {
      setTimeout(this.handleNext, 1000);
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  getAnswerFromRedis = async (
    nextQuestionNumber: number,
    token: string,
    currentTestQuestionId?: any,
    targetTestQuestionId?: any
  ): Promise<boolean> => {
    // set variable to the root DOM element, so we can remove the blur filter once connection is re-established
    const rootElement = document.getElementById("root");
    const timeTaken = this.state.timeTakenRecorded
      ? 0
      : this.calculateTimeTaken(false);

    let questionData;
    questionData = {
      testEventId: this.context.testEventId,
      testMakerTestId: this.props.testMakerTestId,
      currentTestMakerTestQuestionId: currentTestQuestionId,
      nextTestMakerTestQuestionId: targetTestQuestionId,
      timeTaken,
      answer: this.state.answer
    };

    try {
      const response = await fetch(`${nodeApiURL}/getTestMakerTestAnswer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(questionData)
      });

      const responseData = await response.json();
      if (responseData.submitted !== "OK") {
        this.handleRedisFailure();
      } else {
        // remove the blur filter that we set on the root DOM element when showing the InterruptModal
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }

        // reset the state and move to the next question
        this.setState(state => ({
          testMakerTestQuestionId:
            this.props.testData.questions[nextQuestionNumber].id,
          answer: responseData.answer,
          timeTaken: null,
          timeStarted: new Date(),
          showInterruptModal: false,
          failCounter: 0,
          disableNextButton: false,
          timeTakenRecorded: false
        }));

        // if the timer is paused, restart it when progressing to the next question
        if (this.state.pauseTimer) {
          this.setState({ pauseTimer: false });
        }
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  // unlike the multiple choice sendAnswerToRedis function, this one takes `moveToNextQuestion` as a parameter
  // this is because essay questions submit to Redis each time the space bar is pressed, and we don't want to
  // advance the test
  sendAnswerToRedis = async (
    answerData: TestMakerAnswerData,
    moveToNextQuestion: boolean,
    token: string
  ): Promise<boolean> => {
    // set variable to the root DOM element, so we can remove the blur filter once connection is re-established
    const rootElement = document.getElementById("root");

    // for any question type other than an essay question (since essay questions send the answer on click of the space
    // bar, and not at the end of the answer), we disable the next button and pause the timer while waiting for the
    // answer to submit successfully to redis.
    if (moveToNextQuestion) {
      this.setState({ disableNextButton: true, pauseTimer: true });
    }

    // send the answer data to redis
    try {
      const response = await fetch(`${nodeApiURL}/sendTestMakerTestAnswer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(answerData)
      });
      const responseData = await response.json();

      if (responseData.submitted !== "OK") {
        this.handleRedisFailure();
      } else {
        // remove the blur filter that we set on the root DOM element when showing the InterruptModal
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }

        // reset the state and move to the next question

        if (moveToNextQuestion) {
          // if all questions have been answered, proceed to the next subTest in the battery
          if (
            this.props.testData.questions.length - 1 ===
            this.state.questionNumber
          ) {
            this.props.endSubTest();
          } else if (this.props.testData.showPagination) {
            const currentTestQuestionId = this.state.testMakerTestQuestionId;
            const targetTestQuestionId =
              this.props.testData.questions[this.state.questionNumber + 1].id;
            this.setState(
              {
                questionNumber: this.state.questionNumber + 1
              },
              () => {
                this.getAnswerFromRedis(
                  this.state.questionNumber,
                  this.context.token,
                  currentTestQuestionId,
                  targetTestQuestionId
                );
              }
            );
          } else {
            this.setState(state => ({
              questionNumber: state.questionNumber + 1,
              testMakerTestQuestionId:
                this.props.testData.questions[state.questionNumber + 1].id,
              answer: "",
              timeTaken: null,
              timeStarted: new Date(),
              showInterruptModal: false,
              failCounter: 0,
              individualQuestionExpiredTime:
                this.props.testData.questions[state.questionNumber + 1]
                  .timeLimit * this.props.timeFactor,
              disableNextButton: false,
              timeTakenRecorded: false
            }));
          }
        } else {
          this.setState({
            timeTaken: null,
            timeStarted: new Date(),
            timeTakenRecorded: false
          });
        }

        // if the timer is paused, restart it when progressing to the next question
        if (this.state.pauseTimer) {
          this.setState({ pauseTimer: false, disableNextButton: false });
        }
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  render() {
    const { testData, endSubTest, language } = this.props;

    const getQuestionStem = () => {
      return { __html: testData.questions[this.state.questionNumber].stem };
    };

    /* ---------- The AnswerOption Component --------- */

    const getAnswerLabel = (key: string) => {
      return {
        __html: testData.questions[this.state.questionNumber].answers[key]
      };
    };

    let AnswerOptions;

    if (testData.questions[this.state.questionNumber].answers) {
      AnswerOptions = Object.keys(
        testData.questions[this.state.questionNumber].answers
      ).map((key: string) => {
        return (
          <tr key={key}>
            <td className="tmAnswerSelection">
              {testData.questions[this.state.questionNumber].questionType ==
              6 ? (
                <input
                  type="checkbox"
                  name="answerOption"
                  value={key}
                  onChange={this.handleCheckAnswerInput}
                  checked={
                    this.state.answer !== null &&
                    this.state.answer.includes(key)
                  }
                  id={`answer_${key}`}
                />
              ) : (
                <input
                  type="radio"
                  name="answerOption"
                  value={key}
                  onChange={this.handleAnswerInput}
                  checked={this.state.answer === key}
                  id={`answer_${key}`}
                />
              )}
            </td>
            <td>
              <label htmlFor={`answer_${key}`}>
                <div dangerouslySetInnerHTML={getAnswerLabel(key)} />
              </label>
            </td>
          </tr>
        );
      });
    }

    /* ---------- The Page Navigation Component --------- */

    const PageNavigation = Object.keys(testData.questions).map(key => {
      const isAnswered = this.state.answeredArray.includes(
        String(
          this.context.testEventData.tests[
            this.context.testIdsArray[this.context.testIndex]
          ].details.questions[key].id
        )
      );
      return (
        <Button
          variant={isAnswered ? "primary" : "outline-primary"}
          className="button-less-padding ml-2"
          size="sm"
          onClick={this.handlePageNavigation}
          key={key}
          data-question={key}
        >
          {parseInt(key, 10) + 1}
        </Button>
      );
    });

    // Find the image width from the array of image widths in state
    const thisImage = this.state.images?.find(
      (image: any) =>
        image.id === testData.questions[this.state.questionNumber].id
    );
    const imageWidth = thisImage ? thisImage.width : 0;

    // the `timeAllowed` property contains either the total time allowed, or the estimated time allowed, so
    // if time individual questions, then show the timer for that, otherwise, show the overall test limit timer
    return (
      <Container>
        <Row>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            md={{ span: 10, offset: 1 }}
            sm={12}
          >
            {testData.timeLimit || testData.timeQuestions ? (
              <Row>
                <Col>
                  {this.props.dataLoaded ? (
                    <ContinuousTimer
                      seconds={
                        this.props.testData.timeQuestions
                          ? this.state.individualQuestionExpiredTime
                          : this.props.timeRemaining
                      }
                      questionNumber={
                        this.props.testData.timeQuestions
                          ? this.state.questionNumber
                          : null
                      }
                      onCompletion={
                        this.props.testData.timeQuestions
                          ? this.handleIndividualTimeExpiration
                          : endSubTest
                      }
                      startTimer={true}
                      color="black"
                      pauseTimer={this.state.pauseTimer}
                      testData={this.props.testData}
                      testMakerTestId={this.props.testMakerTestId}
                      token={this.context.token}
                      testEventId={this.context.testEventId}
                      timeFactor={this.props.timeFactor}
                    />
                  ) : null}
                </Col>
              </Row>
            ) : null}
            <Row className="mb-1">
              <Col id="progress-bar">
                <ProgressBar
                  width={
                    ((this.state.questionNumber + 1) /
                      testData.questions.length) *
                    100
                  }
                />
                <p className="questionCounter">
                  {["zh-cn", "chinese"].includes(language) ? (
                    <>
                      问题<strong>{this.state.questionNumber + 1}</strong> (共
                      <strong>{testData.questions.length}</strong>个问题)
                    </>
                  ) : (
                    <>
                      {translate("common", "question", language)}{" "}
                      <strong>{this.state.questionNumber + 1}</strong>{" "}
                      {translate("common", "divide", language)}{" "}
                      <strong>{testData.questions.length}</strong>
                    </>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
                <br />
                {this.state.error !== null ? (
                  <Alert variant="danger" className="text-center">
                    {this.state.error}
                  </Alert>
                ) : null}
                {testData.questions[this.state.questionNumber].image !== "" ? (
                  <Row>
                    <Col className="text-center">
                      <Image
                        fluid={true}
                        src={`https://${s3BucketName}.s3.${s3Region}.amazonaws.com/hireselect/userfiles/${
                          testData.questions[this.state.questionNumber].image
                        }`}
                      />
                      <br />
                      {imageWidth > 750 ? (
                        <Button
                          variant="link"
                          onClick={this.handleEnlarge}
                          className="my-3"
                        >
                          <FaExpandArrowsAlt /> Enlarge Image
                        </Button>
                      ) : null}
                      {this.state.imageIsOpen && (
                        <Lightbox
                          mainSrc={`https://${s3BucketName}.s3.${s3Region}.amazonaws.com/hireselect/userfiles/${
                            testData.questions[this.state.questionNumber].image
                          }`}
                          onCloseRequest={this.handleEnlargeClose}
                        />
                      )}
                    </Col>
                  </Row>
                ) : null}
                <div
                  className="test-question"
                  dangerouslySetInnerHTML={getQuestionStem()}
                />

                <table className="tmAnswers" style={{ width: "100%" }}>
                  <tbody>
                    {testData.questions[this.state.questionNumber]
                      .questionType == 5 ? (
                      <tr>
                        <td>
                          <Form.Control
                            as="textarea"
                            name="answerOption"
                            value={this.state.answer}
                            onKeyDown={this.handleEssayEntry}
                            onChange={this.handleEssayEntryText}
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        </td>
                      </tr>
                    ) : (
                      AnswerOptions
                    )}
                  </tbody>
                </table>

                <NextButton
                  handler={this.handleNext}
                  disableNextButton={this.state.disableNextButton}
                  buttonLabel={
                    this.props.testData.subTestText?.submit ??
                    translate("common", "submitAnswer", language) ??
                    "Submit Answer"
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {this.props.testData.showPagination ? PageNavigation : null}
              </Col>
            </Row>
          </Col>

          {this.state.showInterruptModal ? (
            <InterruptModal>
              <Col>
                <h1 style={{ fontSize: "150px", margin: "50px 0" }}>
                  <MdSignalWifiOff />
                </h1>
                <h3>
                  <strong>Test Paused</strong>
                </h3>
                {!this.state.didReconnectFail ? (
                  <div className="text-left">
                    <p>
                      There was an error while trying to save your answer. Your
                      timer will be paused until the test is resumed.
                    </p>
                    <p>Please wait while we try to reconnect.</p>
                  </div>
                ) : (
                  <div className="text-left">
                    <p>
                      We were unable to reconnect. Please try again later or
                      from a different device.
                    </p>
                  </div>
                )}
                {!this.state.didReconnectFail ? (
                  <BarLoader height={6} width={150} color={"#428bca"} />
                ) : (
                  <div className="text-center">
                    <Button
                      variant="success"
                      id="retry-connection-button"
                      onClick={this.handleRetryButton}
                    />
                    <p>
                      We were unable to reconnect. Please try again later or
                      from a different device.
                    </p>
                  </div>
                )}
                {!this.state.didReconnectFail ? (
                  <BarLoader height={6} width={150} color={"#428bca"} />
                ) : (
                  <div className="text-center">
                    <Button
                      variant="success"
                      id="retry-connection-button"
                      onClick={this.handleRetryButton}
                    >
                      Retry
                    </Button>
                  </div>
                )}
              </Col>
            </InterruptModal>
          ) : null}
        </Row>
      </Container>
    );
  }
}
