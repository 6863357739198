import Language from "../../Interfaces/Language";

export const ja: Language = {
  common: {
    help: "お困りですか？",
    helpCenter: "ヘルプセンター",
    expect: "この診断で期待されること",
    faq: "よくある質問",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/6irxfxd6a6-japanese",
    copyright: "著作権",
    copyrightNotices: "著作権について",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/ybp6yd696y-japanese-qpa",
    terms: "利用規約",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/lxlar97mkd-japanese-bsg-kza-tli",
    privacy: "個人情報保護方針",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/twzorm2ds9-japanese-bsg-kza",
    dmca: "障がい／配慮事項",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/extqfcss1z-japanese-bsg",
    accommodations: "障がい／配慮事項",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/wn2odh06ym-japanese",
    technicalSupport: "テクニカルサポート",
    chatSupport:
      "サポートについては、本ページ右下の弊社チームとのチャットをご利用ください",
    continueButton: "続ける",
    submitAnswer: "答えを提出",
    question: "質問",
    divide: "/",
    selectAnswer: "質問に答えてから次に進んでください。",
    textToType: "タイプするテキスト",
    typePassageBelow: "以下に文章をタイプしてください"
  },
  overview: {
    minutes: "分",
    approx: "およそ"
  },
  instruction: {
    eppCopyright:
      "この評価には、1995年のSalesAP©と2002年のCSAP©、Multi-Health Systems Inc.の著作物が複製されています。全ての権利を保有しています。",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "評価は一時停止されました。",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "診断テストを受けている間は、ブラウザのウィンドウを放置することや、テスト画面以外の所をクリックすることが <b>ない</b> ようにしてください。",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "インターネット接続に問題がある場合は、",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "こちらをクリックして",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF:
      "https://criteriacandidates.helpdocs.io/article/6irxfxd6a6-japanese",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      "トラブルシューティングのヒントをご覧ください。",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "テストの機密性を保つ必要上、テスト画面を放置しておくと <b>ロックアウト</b> され、診断テストを完了することができない可能性があります。",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "テストの機密性を保つ必要上、あと <b>2 回</b> テスト画面を放置してしまうと、<b>ロックアウト</b> されます。",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>テストの機密性を保つ必要上、もう一度テスト画面を放置してしまうと、ロックアウトされます。</b>",
    BUTTON_RESUME: "再開"
  },
  error: {
    common: {
      UH_OH: "残念！"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "ネットワークの問題で読み込むことができませんでした。インターネットの通信状態を確認し、しばらくしてからページを更新してください。",
      MESSAGE_PARAGRAPH_2_SECTION_1: "問題が解消されない場合は、",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "サポートFAQ",
      MESSAGE_PARAGRAPH_2_SECTION_2: "を確認してください。",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/6irxfxd6a6-japanese"
    }
  }
};
