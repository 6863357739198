import React, { FunctionComponent, useContext } from "react";
import { LanguageContext } from "../../../../Providers/LanguageProvider";

import FormattedMessage from "../../../utility/message/FormattedMessage";
import styles from "../Lockout.module.css";

type MessageProps = {
  isOverWarningLimit: boolean;
};

export const AlertMessageSecondParagraph: FunctionComponent<MessageProps> = ({
  isOverWarningLimit
}) => {
  const language = useContext(LanguageContext)["lockout"];
  return isOverWarningLimit ? (
    <p>
      {language.LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1}
      <b>
        <a
          className={styles["help-link"]}
          href={language.LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF}
          target="_blank"
          rel="noopener noreferrer"
        >
          {language.LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK}
        </a>
      </b>
      {language.LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2}
    </p>
  ) : (
    <FormattedMessage message={language.LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2} />
  );
};
