import Language from "../../Interfaces/Language";

export const de: Language = {
  common: {
    help: "Benötigen Sie Hilfe?",
    helpCenter: "Hilfebereich",
    expect: "Was Sie bei diesen Tests erwartet",
    faq: "Häufig gestellte Fragen",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/7slzxfqnp1-h-ufig-gestellte-fragen-german",
    copyright: "Copyright",
    copyrightNotices: "Copyright-Hinweise",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/e6enxx0355-copyright-hinweis-german",
    terms: "Nutzungsbedingungen",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/rt2kisio1s-nutzungsbedingungen-german",
    privacy: "Datenschutzerklärung",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/u8bk63ngjz-datenschutzrichtlinie-german",
    dmca: "DMCA-Richtlinie",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/hu997cur6w-politik-des-digital-millenium-copyright-act-german",
    accommodations: "Behinderung / Spezielle Vorkehrungen",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/vyrzybgnd3-behinderung-barrierefreiheit-german",
    technicalSupport: "Technischer Support",
    chatSupport:
      "Falls Sie Unterstützung benötigen, chatten Sie bitte mit unserem Team rechts unten auf dieser Seite",
    continueButton: "Weiter",
    submitAnswer: "Antwort absenden",
    question: "Frage",
    divide: "/",
    selectAnswer: "Sie müssen die Frage beantworten, um fortzufahren.",
    textToType: "Text zum Tippen",
    typePassageBelow: "Geben Sie den Text unten ein"
  },
  overview: {
    minutes: "Min.",
    approx: "etwa"
  },
  instruction: {
    eppCopyright:
      "Diese Bewertung beinhaltet reproduziertes Material von SalesAP ©1995 und CSAP ©2002, Multi-Health Systems Inc. Alle Rechte vorbehalten.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Ihre Bewertung wurde pausiert.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Bitte verlassen Sie <b>nicht</b> Ihr Browserfenster und klicken Sie nicht außerhalb des Assessmentbereichs, solange der Test läuft.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Wenn Sie Internetverbindungsprobleme haben, ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "hier klicken",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK_HREF:
      "https://criteriacandidates.helpdocs.io/article/7slzxfqnp1-h-ufig-gestellte-fragen-german",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " für einige Tipps zur Fehlerbehebung.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Wenn Sie das Assessment verlassen, werden Sie aus Sicherheitsgründen <b>ausgesperrt</b>, was Sie daran hindern kann, Ihr Assessment abzuschließen.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Wenn Sie das Assessment <b>noch zweimal</b> verlassen, werden Sie aus Sicherheitsgründen <b>ausgesperrt</b>.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Wenn Sie das Assessment noch einmal verlassen, werden Sie aus Sicherheitsgründen ausgesperrt.</b>",
    BUTTON_RESUME: "Fortsetzen"
  },
  error: {
    common: {
      UH_OH: "Oje!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Die Seite kann aufgrund von Netzwerkproblemen nicht geladen werden. Bitte überprüfen Sie Ihre Internetverbindung und aktualisieren Sie die Seite in wenigen Augenblicken.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "Wenn das Problem weiterhin besteht, lesen Sie bitte unsere ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/7slzxfqnp1-h-ufig-gestellte-fragen-german"
    }
  }
};
