import React, { FunctionComponent } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import MrabCastTest from "../../Tests/TestDisplays/Mrab/MrabCast";
import { getMrabStartingTask } from "../../utils/redisHelpers";
import NetworkError from "../utility/error/network/NetworkError";
import Loader from "../utility/loader/Loader";

const MrabCast: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getMrabStartingTask>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  // if we are starting the cast at section 4 we should jump to the final task group
  if (
    (props.subTestId === "13" || props.subTestId === "18") &&
    data.startingTaskNumber === 4
  ) {
    data.startingTaskNumber = 9;
  }

  return <MrabCastTest {...props} {...data} />;
};

export default MrabCast;
