import React, { Component } from "react";
import queryString from "query-string";
import { Container, Row, Col } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { browserName, isMobile } from "react-device-detect";
import { TestEventContext } from "../Contexts/TestEventContext";
import Footer from "../Components/Footer";
import WelcomePage from "./WelcomePage";
import BrowserWarning from "./BrowserWarning";
import { navigate } from "@reach/router";
import CalendarWarning from "./CalendarWarning";
import PersonalInformation from "./PersonalInformation";

/* ---------- Interfaces ---------- */

interface Props {
  path: string;
  language: string;
  location?: any;
  testEventData?: any;
  jobCode?: string;
  testBatteryId?: string;
  userAccountId?: string;
  first?: string;
  last?: string;
  email?: string;
  orderId?: string;
  returnUrl?: string;
  fetchTestEvent?: any;
  contactInfo?: any;
  eventId?: string;
  fromLink?: boolean;
  updateFromEventIdLink?: (value: boolean) => void;
  updateLinkParams?(
    u: any,
    first: any,
    last: any,
    email: any,
    orderId: any,
    source: any,
    returnUrl: any
  ): void;
  updateExitPage?(returnUrl: any): void;
  retrievingData?: boolean;
  updateIsMobile?(isMobile: boolean): void;
  referrer?: {
    id: number | null;
    url: string;
  };
}

interface State {
  noPII: boolean;
  showWelcomePage: boolean;
  showInvalidBrowser: boolean;
  isInvalidBrowser: boolean;
  showCalendarWarning: boolean;
  componentShowing: string | null;
}
class VerifyForm extends Component<Props> {
  state: State = {
    noPII: false,
    showWelcomePage: true,
    showInvalidBrowser: false,
    isInvalidBrowser: false,
    showCalendarWarning: false,
    componentShowing: null
  };
  // Declare a static contextType: any so that we can use this.context throughout the class.
  static contextType: any = TestEventContext;

  componentDidMount() {
    /* ----------------------- Begin Test Data Retrieval -------------------------------- */
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.props.location.search !== ""
      ? this.props.fetchTestEvent(
          this.props.jobCode,
          queryString.parse(this.props.location.search).u
        )
      : this.props.jobCode
      ? this.props.fetchTestEvent(this.props.jobCode)
      : null;

    if (this.props.testBatteryId && this.props.userAccountId) {
      this.props.fetchTestEvent(
        this.props.testBatteryId,
        this.props.userAccountId
      );
    }
    if (this.props.eventId) {
      this.props.fetchTestEvent(
        this.props.eventId.replace(/-/g, ""),
        this.props.fromLink
      );
    }

    /* ------------------------- End Test Data Retrieval ------------------------------ */

    // If the current URL has a query string, we can extract its parameters to eventually set them in context.
    if (this.props.location.search !== "" && this.props.updateLinkParams) {
      // We should not decode the returnUrl parameter because it could have its own parameters
      const returnUrl = queryString.parse(this.props.location.search, {
        decode: false
      }).returnUrl;

      // All other possible parameters can be decoded by default; this ensures legible autofilling
      // of candidate info in <TestTakerForm>. If no decoding is needed, they won't be affected
      const { u, first, last, email, orderId, source } = queryString.parse(
        this.props.location.search
      );

      if (
        (u !== null && u !== undefined) ||
        (first !== null && first !== undefined) ||
        (last !== null && last !== undefined) ||
        (email !== null && email !== undefined) ||
        (orderId !== null && orderId !== undefined) ||
        (source !== null && source !== undefined) ||
        (returnUrl !== null && returnUrl !== undefined)
      ) {
        this.props.updateLinkParams(
          u,
          first,
          last,
          email,
          orderId,
          source,
          returnUrl
        );
      }
      if (
        this.props.updateExitPage &&
        returnUrl !== null &&
        returnUrl !== undefined
      ) {
        this.props.updateExitPage(returnUrl);
      }
    }
    if (this.props.contactInfo && this.props.updateLinkParams) {
      const contactInfoArray = this.props.contactInfo.split("~");
      this.props.updateLinkParams(
        null,
        contactInfoArray[0],
        contactInfoArray[1],
        contactInfoArray[2],
        null,
        null,
        null
      );
    }
    if (
      (this.props.orderId ||
        this.props.first ||
        this.props.last ||
        this.props.email) &&
      this.props.updateLinkParams
    ) {
      this.props.updateLinkParams(
        null,
        this.props.first,
        this.props.last,
        this.props.email,
        this.props.orderId,
        null,
        null
      );
    }
    if (this.props.returnUrl && this.props.updateExitPage) {
      this.props.updateExitPage(this.props.returnUrl);
    }

    // determine if the testEvent is from a no-PII account
    let noPII = false;
    if (this.context.testEventData.anonymous) {
      if (this.context.eventId !== "") {
        noPII = true;
      }
    }
    this.setState({ noPII });

    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });

    // determine if the user's browser is not a recommended browser, and if they are on a mobile device
    const supportedBrowsers = [
      "Chrome",
      "HeadlessChrome",
      "Firefox",
      "Safari",
      "Mobile Safari",
      "Edge",
      "IE",
      "Facebook"
    ];
    if (!supportedBrowsers.includes(browserName)) {
      this.setState({ isInvalidBrowser: true });
    }

    if (isMobile && this.props.updateIsMobile) {
      this.props.updateIsMobile(isMobile);
    }

    if (this.context.eventId !== "" && !this.context.fromLink) {
      this.setState({ showWelcomePage: false, showInvalidBrowser: false });
    }

    // state & calendarRedirect value are only passed in from location upon redirect
    // from deprecated "schedule/calendar/*" path
    if (
      this.props.location.state &&
      this.props.location.state.calendarRedirect
    ) {
      this.setState({ showWelcomePage: false, showCalendarWarning: true });
    }
  }

  handleWelcomePageContinue = () => {
    if (this.state.isInvalidBrowser) {
      return this.setState({
        showWelcomePage: false,
        showInvalidBrowser: true
      });
    }

    if (
      this.context.eventId === "" &&
      this.context.jobCode === "" &&
      (Object.keys(this.context.testEventData).length === 0 ||
        this.context.errorMessage)
    ) {
      return navigate("/event_id_form");
    }

    if (this.props.updateFromEventIdLink) {
      this.props.updateFromEventIdLink(false);
    }

    this.setState({ showWelcomePage: false, showInvalidBrowser: false });
  };

  handleBrowserWarningContinue = () => {
    if (this.context.eventId === "" && this.context.jobCode === "") {
      navigate("/event_id_form");
    } else {
      this.setState({ showWelcomePage: false, showInvalidBrowser: false });
    }
  };

  handleUpdateComponentShowing = (componentName: string) => {
    this.setState({ componentShowing: componentName });
  };

  render() {
    const landingPageStyle = this.context.generateLandingPageStyle(
      this.context.testEventData.landingPage
    );
    return (
      <Container
        style={{
          backgroundColor: landingPageStyle.formBackgroundColor
        }}
      >
        <Row>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            md={{ span: 10, offset: 1 }}
            sm={12}
          >
            {this.props.retrievingData &&
              this.state.showWelcomePage &&
              !this.state.showInvalidBrowser &&
              !this.state.showCalendarWarning && (
                <Row>
                  <Col className="spinner-container">
                    <div className="spinner text-center">
                      <FadeLoader
                        height={20}
                        width={20}
                        radius={20}
                        margin={30}
                        color="#B1B3B3"
                      />
                    </div>
                  </Col>
                </Row>
              )}
            {(this.state.showWelcomePage || this.context.fromEventIdLink) &&
            !this.state.showInvalidBrowser &&
            !this.context.retrievingData ? (
              <WelcomePage
                landingPageStyle={landingPageStyle}
                continueButtonHandler={this.handleWelcomePageContinue}
                handleUpdateComponentShowing={this.handleUpdateComponentShowing}
                language={this.props.language}
              />
            ) : null}
            {!this.state.showWelcomePage &&
            !this.context.fromEventIdLink &&
            this.state.showInvalidBrowser ? (
              <BrowserWarning
                landingPageStyle={landingPageStyle}
                continueButtonHandler={this.handleBrowserWarningContinue}
                handleUpdateComponentShowing={this.handleUpdateComponentShowing}
              />
            ) : null}
            {this.state.showCalendarWarning &&
            !this.state.showWelcomePage &&
            !this.context.fromEventIdLink &&
            !this.state.showInvalidBrowser ? (
              <CalendarWarning
                landingPageStyle={landingPageStyle}
                handleUpdateComponentShowing={this.handleUpdateComponentShowing}
              />
            ) : null}
            {!this.state.showWelcomePage &&
            !this.context.fromEventIdLink &&
            !this.state.showInvalidBrowser &&
            !this.state.showCalendarWarning ? (
              <PersonalInformation
                landingPageStyle={landingPageStyle}
                noPII={this.state.noPII}
                continueButtonHandler={() => {}}
                referrer={this.props.referrer}
                handleUpdateComponentShowing={this.handleUpdateComponentShowing}
                language={this.props.language}
              />
            ) : null}
          </Col>
        </Row>
        {!this.props.retrievingData ? <Footer /> : null}
      </Container>
    );
  }
}
export default VerifyForm;
