import React, { FunctionComponent, useContext } from "react";
import { TestEventContext } from "../../../Contexts/TestEventContext";
import useVisibility from "../../../CustomHooks/useVisibility";
import { TokenContext } from "../../../Providers/TokenProvider";
import NetworkError from "../../utility/error/network/NetworkError";
import LockoutMessage from "./LockoutMessage";

const LockoutController: FunctionComponent = ({ children }) => {
  const { returnUrl } = useContext(TokenContext);
  const TestEvent = useContext(TestEventContext);
  const { isHidden, refocus, lockout, error } = useVisibility();

  if (error) {
    return <NetworkError />;
  }

  if ((isHidden && lockout.isLockout) || TestEvent.lockout.isLockout) {
    const lockoutLocation = new URL(returnUrl);
    window.location.replace(lockoutLocation.origin + lockoutLocation.pathname);
    return <LockoutMessage lockout={lockout} refocus={refocus} />;
  }

  return isHidden && TestEvent.useLockout ? (
    <LockoutMessage lockout={lockout} refocus={refocus} />
  ) : (
    children
  );
};

export default LockoutController;
