import { useContext, useEffect, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import { Lockout } from "../Interfaces/Lockout";
import { TokenContext } from "../Providers/TokenProvider";
import { Hook } from "../utils/Reference/Hook";
import { cxURL } from "../utils/constants";

type VisibilityResult = {
  isHidden: boolean;
  refocus: () => void;
  lockout: Lockout;
  error: Boolean;
};

const useVisibility: Hook<void, VisibilityResult> = () => {
  const { eventId, assessment, returnUrl } = useContext(TokenContext);
  const TestEvent = useContext(TestEventContext);
  const [isHidden, setIsHidden] = useState(TestEvent.hideOnInit);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function postLockout(
      eventId: string | null,
      assessment: string,
      lockout: number
    ): Promise<Lockout> {
      const response = await fetch(`${cxURL}/e/${eventId}/lockout`, {
        method: "POST",
        body: JSON.stringify({ assessment, lockout }),
        keepalive: true
      });
      if (!response.ok) {
        setIsError(() => true);
      }
      const content: Lockout = await response.json();
      return content;
    }
    const visibilityChangeHandler = async () => {
      if (
        TestEvent.useLockout &&
        !isHidden &&
        !TestEvent.errorIsShowing &&
        document.visibilityState === "hidden"
      ) {
        TestEvent.lockout = await postLockout(
          eventId,
          assessment,
          TestEvent.lockout.lockout + 1
        );
        setIsHidden(true);
      }
    };
    const focusLossHandler = async () => {
      if (TestEvent.useLockout && !isHidden && !TestEvent.errorIsShowing) {
        TestEvent.lockout = await postLockout(
          eventId,
          assessment,
          TestEvent.lockout.lockout + 1
        );
        setIsHidden(true);
      }
    };
    window.addEventListener("visibilitychange", visibilityChangeHandler);
    window.addEventListener("blur", focusLossHandler);
    return () => {
      window.removeEventListener("visibilitychange", visibilityChangeHandler);
      window.removeEventListener("blur", focusLossHandler);
    };
  }, [TestEvent, assessment, eventId, isHidden, returnUrl]);

  return {
    isHidden,
    refocus: () => {
      TestEvent.hideOnInit = false;
      setIsHidden(false);
    },
    lockout: TestEvent.lockout,
    error: isError
  };
};

export default useVisibility;
