import { navigate } from "@reach/router";
import React, { Component } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { MdSignalWifiOff } from "react-icons/md";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { BarLoader } from "react-spinners";
import InterruptModal from "../../../Components/InterruptModal";
import NextButton from "../../../Components/NextButton";
import ProgressBarComponent from "../../../Components/ProgressBar";
import Timer from "../../../Components/Timer";
import { TestEventContext } from "../../../Contexts/TestEventContext";
import { MultipleChoiceAnswerData } from "../../../Interfaces/AnswerData";
import { nodeApiURL } from "../../../utils/constants";
import { sendTimeRemaining } from "../../../utils/redisHelpers";
import { isEventExpired } from "../../../utils/shared";
import { translate } from "../../../utils/translationHelpers";

interface State {
  questionNumber: number;
  answer: string;
  timeStarted: Date | null;
  timeTaken: number | null;
  error: string | null;
  showInterruptModal: boolean;
  pauseTimer: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  timeRemaining: number;
  disableNextButton: boolean;
  images: any;
  imageIsOpen: boolean;
}
interface Props {
  testData: any;
  subTestId: string;
  startingQuestion: number;
  timeRemaining: number;
  dataLoaded: boolean;
  endSubTest: () => void;
  simulationTimeAllowed?: number;
  isCLIKv2?: boolean;
  language: string;
}

export default class MultipleChoiceTest extends Component<Props, State> {
  state: State = {
    questionNumber: 0,
    answer: "",
    timeStarted: null,
    timeTaken: null,
    error: null,
    showInterruptModal: false,
    pauseTimer: false,
    failCounter: 0,
    didReconnectFail: false,
    timeRemaining: this.props.timeRemaining,
    disableNextButton: false,
    images: [],
    imageIsOpen: false
  };

  // define the context type so that we can use this.context throughout the class
  static contextType = TestEventContext;

  // declare class property type
  timerID: any;

  /* ---------- React LifeCycle Methods ---------- */

  componentDidMount() {
    // add a page visibility event listener in order to determine when a different
    // browser tab is selected.
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );

    // set a time to be used in calculating the timeTaken field when the first
    // answer is submitted.
    this.setState({ timeStarted: new Date() });
    // this MC Test component is a shared component. for now, we only want to enable the enlarge image functionality for CLIK v2.
    if (this.props.isCLIKv2) {
      const images: any[] = [];
      this.props.testData.questions.forEach((question: any) => {
        if (question.image) {
          let image = document.createElement("img");
          image.src = `https://s3.amazonaws.com/criteria-test-images/${question.image}`;
          const poll = setInterval(() => {
            if (image.naturalWidth) {
              clearInterval(poll);
              images.push({ id: question.id, width: image.naturalWidth });
              this.setState({ images });
            }
          }, 10);
        }
      });
    }
    // once the component is mounted, start the timer.
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
    const rootElement = document.getElementById("root");
    // remove the blur filter that we set on the root DOM element when showing the InterruptModal
    if (rootElement !== null) {
      rootElement.style.filter = "none";
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    return nextProps.startingQuestion > 0 && prevState.questionNumber === 0
      ? {
          questionNumber: nextProps.startingQuestion
        }
      : null;
  }

  /* ---------- Timer Functions ---------- */

  // method to start the timer
  startTimer = () => {
    this.timerID = setInterval(() => this.tick(), 1000);
  };

  stopTimer = () => {
    window.clearInterval(this.timerID);
  };

  // method to decrement the seconds value is state whenever it is called
  tick = () => {
    if (this.state.showInterruptModal) {
      sendTimeRemaining(
        this.state.timeRemaining,
        this.context.testEventId,
        this.props.subTestId,
        this.context.token
      )
        .then((timeResponse: any) => {
          if (!timeResponse) {
            this.handleRedisFailure();
          } else {
            const rootElement = document.getElementById("root");
            // remove the blur filter that we set on the root DOM element when showing the InterruptModal
            if (rootElement !== null) {
              rootElement.style.filter = "none";
            }
            this.setState(state => ({
              showInterruptModal: false,
              pauseTimer: false,
              failCounter: 0
            }));
          }
        })
        .catch(error => {
          console.log("error: ", error);
        });
    }
    if (!this.state.disableNextButton && !this.state.pauseTimer) {
      this.setState(
        state => ({
          timeRemaining: state.timeRemaining - 1
        }),
        () => {
          // CLIK Tests
          let simulationSections = 0;
          if (this.props.subTestId === "34") {
            simulationSections = 2;
          } else if (this.props.subTestId === "135") {
            simulationSections = 3;
          }
          const newTimeRemaining =
            this.props.subTestId === "131"
              ? this.props.testData.questionTimeLimit - this.state.timeRemaining // adaptive CCAT is the only Multiple Choice Test in which each question is timed individually
              : (this.props.subTestId === "34" ||
                  this.props.subTestId === "135") &&
                this.props.simulationTimeAllowed
              ? this.props.testData.timeAllowed -
                this.props.simulationTimeAllowed * simulationSections -
                this.state.timeRemaining
              : this.props.testData.timeAllowed - this.state.timeRemaining;
          try {
            sendTimeRemaining(
              newTimeRemaining,
              this.context.testEventId,
              this.props.subTestId,
              this.context.token
            )
              .then((timeResponse: any) => {
                if (!timeResponse) {
                  this.handleRedisFailure();
                } else {
                  const rootElement = document.getElementById("root");
                  // remove the blur filter that we set on the root DOM element when showing the InterruptModal
                  if (rootElement !== null) {
                    rootElement.style.filter = "none";
                  }
                  this.setState(state => ({
                    showInterruptModal: false,
                    pauseTimer: false,
                    failCounter: 0
                  }));
                }
              })
              .catch(error => {
                console.log("error: ", error);
              });
          } catch (error) {
            console.log("error: ", error);
          }
        }
      );
    }
  };

  /* ---------- Event Handlers ---------- */

  handleNext = () => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      // calculate the time taken to answer the question
      const newTime: Date = new Date();

      // convert timeStarted and newTime to milliseconds and find the difference, then convert back to seconds
      const timeStartedInMilliseconds =
        this.state.timeStarted !== null ? this.state.timeStarted.getTime() : 0;
      const newTimeInMilliseconds = newTime.getTime();

      const timeTaken =
        (newTimeInMilliseconds - timeStartedInMilliseconds) / 1000;

      // scroll to top of the screen
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });

      // set the answerData object that we then send to redis to record data for each answer
      if (this.state.answer) {
        let answerData: MultipleChoiceAnswerData;

        // if this is the first time through (no internet connection interrupt), we need to set the timeTaken in state
        // prior to creating the answerData object and sending it to redis.  if this is a subsequent try due to
        // connection issues, the timeTaken is already set and we can skip the setState part.  this is primarily
        // to avoid small increases in the timeTaken for each second that reconnect is attempted.
        if (this.state.timeTaken === null) {
          this.setState({ timeTaken }, () => {
            answerData = {
              testEventId: this.context.testEventId,
              subTestId: this.props.subTestId,
              questionNumber: this.state.questionNumber + 1,
              answer: this.state.answer,
              timeTaken:
                this.state.timeTaken !== null ? this.state.timeTaken : 0,
              questionId:
                this.context.testEventData.tests[this.props.subTestId].details
                  .questions[this.state.questionNumber].id
            };
            this.sendAnswerToRedis(answerData);
          });
        } else {
          answerData = {
            testEventId: this.context.testEventId,
            subTestId: this.props.subTestId,
            questionNumber: this.state.questionNumber + 1,
            answer: this.state.answer,
            timeTaken: this.state.timeTaken !== null ? this.state.timeTaken : 0,
            questionId:
              this.context.testEventData.tests[this.props.subTestId].details
                .questions[this.state.questionNumber].id
          };
          this.sendAnswerToRedis(answerData);
        }

        // reset no answer warning if an answer is selected
        if (this.state.error !== null) {
          this.setState({
            error: null,
            timeTaken: null
          });
        }
      } else {
        this.setState({
          error: this.props.testData.subTestText
            ? this.props.testData.subTestText.answerError
            : "You must answer the question to continue"
        });
      }
    } else {
      // if the event has expired, we need to update the error message, and navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };

  handleIndividualTimeExpiration = () => {
    // scroll to top of the screen
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });

    // set the answerData object that we then send to redis to record data for each answer
    let answerData = {
      testEventId: this.context.testEventId,
      subTestId: this.props.subTestId,
      questionNumber: this.state.questionNumber + 1,
      answer: this.state.answer,
      timeTaken: this.state.timeTaken !== null ? this.state.timeTaken : 0,
      questionId:
        this.context.testEventData.tests[this.props.subTestId].details
          .questions[this.state.questionNumber].id
    };

    this.sendAnswerToRedis(answerData);

    // reset no answer warning if an answer is selected
    if (this.state.error !== null) {
      this.setState({ error: null, timeTaken: null });
    }
  };

  handleAnswerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ answer: event.target.value }, () => {
      if (this.state.error !== null) {
        this.setState({ error: null });
      }
    });
  };

  handleRetryButton = () => {
    this.setState({ didReconnectFail: false }, () => {
      this.handleRedisFailure();
    });
  };

  /* ---------- Utility Functions ---------- */

  // callback to handle when a different browser tab is selected.
  // we want to pause the time when it has been, and resume it when
  // the test tab is reselected.
  handleVisibilityChange = () => {
    if (document.hidden) {
      this.setState({ pauseTimer: true });
    } else {
      this.setState({ pauseTimer: false });
    }
  };

  sendAnswerToRedis = async (
    answerData: MultipleChoiceAnswerData
  ): Promise<boolean> => {
    // set variable to the root DOM element, so we can remove the blur filter once connection is re-established
    const rootElement = document.getElementById("root");
    this.setState(
      {
        disableNextButton: true,
        pauseTimer: true
      },
      () => {
        this.stopTimer();
      }
    );

    try {
      const response = await fetch(
        `${nodeApiURL}/${
          this.props.subTestId === "34" || this.props.subTestId === "135"
            ? "sendCLIKMultipleChoiceAnswer"
            : "sendMultipleChoiceAnswer"
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.context.token
          },
          body: JSON.stringify(answerData)
        }
      );

      const responseData = await response.json();

      if (responseData.submitted !== "OK") {
        this.handleRedisFailure();
      } else {
        // remove the blur filter that we set on the root DOM element when showing the InterruptModal
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }

        // if all questions have been answered, proceed to the next subTest in the battery
        if (
          this.props.testData.questions.length - 1 ===
          this.state.questionNumber
        ) {
          this.props.endSubTest();
        } else {
          // reset the state and move to the next question
          // for adaptive CCAT, also reset the state property `timeRemaining` to full time limit of each question, and reset the expiredTime to 0 in redis
          if (this.props.subTestId === "131") {
            this.setState(
              state => ({
                questionNumber: state.questionNumber + 1,
                answer: "",
                timeTaken: null,
                timeStarted: new Date(),
                showInterruptModal: false,
                failCounter: 0,
                disableNextButton: false,
                pauseTimer: false,
                timeRemaining: this.props.testData.questionTimeLimit
              }),
              () => {
                this.startTimer();
              }
            );
          } else {
            this.setState(
              state => ({
                questionNumber: state.questionNumber + 1,
                answer: "",
                timeTaken: null,
                timeStarted: new Date(),
                showInterruptModal: false,
                failCounter: 0,
                disableNextButton: false,
                pauseTimer: false
              }),
              () => {
                this.startTimer();
              }
            );
          }
          document.getElementById("A")?.focus();
        }
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  handleRedisFailure = () => {
    this.setState(state => ({
      showInterruptModal: true,
      pauseTimer: true,
      failCounter: state.failCounter + 1
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (this.state.failCounter < 30) {
      setTimeout(this.handleNext, 1000);
    } else {
      this.setState({
        didReconnectFail: true,
        failCounter: 0
      });
    }
  };

  handleEnlarge = () => {
    this.setState({ imageIsOpen: true });
  };

  handleEnlargeClose = () => {
    this.setState({ imageIsOpen: false });
  };

  render() {
    const { testData, endSubTest, isCLIKv2, language } = this.props;
    const getQuestionStem = () => {
      return {
        __html: testData.questions[this.state.questionNumber].stem
      };
    };
    const getInstructions = () => {
      return {
        __html: testData.questions[this.state.questionNumber].instructions
      };
    };

    /* ---------- The AnswerOption Component --------- */

    const AnswerOptions = Object.keys(
      testData.questions[this.state.questionNumber].answers
    ).map((key: string) => {
      const answerStem =
        testData.questions[this.state.questionNumber].answers[key];
      return (
        <div className="mcAnswerOption" key={key}>
          <label
            htmlFor={key}
            className={this.state.answer === key ? "selected-answer" : ""}
          >
            <input
              type="radio"
              name="answerOption"
              value={key}
              onChange={this.handleAnswerInput}
              checked={this.state.answer === key}
              id={key}
              className="mx-2"
            />{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: answerStem
              }}
            />
          </label>
        </div>
      );
    });

    // only look for the image width when it is CLIK v2 MC and when there is an image associated with the question
    let imageWidth;
    if (
      isCLIKv2 &&
      testData.questions[this.state.questionNumber].image !== ""
    ) {
      // Find the image width from the array of image widths in state
      const thisImage = this.state.images?.find(
        (image: any) =>
          image.id === testData.questions[this.state.questionNumber].id
      );
      imageWidth = thisImage ? thisImage.width : 0;
    }

    return (
      <Container className="no-select">
        <Row>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            md={{ span: 10, offset: 1 }}
            sm={12}
            dir={testData.direction ? testData.direction : "ltr"}
          >
            <Row>
              <Col>
                {this.props.dataLoaded ? (
                  <Timer
                    seconds={
                      this.props.subTestId === "131"
                        ? this.state.timeRemaining
                        : this.props.timeRemaining
                    }
                    questionNumber={
                      this.props.subTestId === "131"
                        ? this.state.questionNumber
                        : null
                    }
                    subTestId={this.props.subTestId}
                    onCompletion={
                      this.props.subTestId === "131"
                        ? this.handleIndividualTimeExpiration
                        : endSubTest
                    }
                    startTimer={true}
                    color="black"
                    pauseTimer={this.state.pauseTimer}
                  />
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col id="progress-bar">
                {this.props.dataLoaded ? (
                  <ProgressBarComponent
                    width={
                      ((this.state.questionNumber + 1) /
                        testData.questions.length) *
                      100
                    }
                  />
                ) : null}
                {this.props.dataLoaded ? (
                  <p className="questionCounter mb-0">
                    {this.props.testData.subTestText ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.props.testData.subTestText.question
                            .replace(
                              "{[QUESTION_NUMBER]}",
                              "<strong>" +
                                (this.state.questionNumber + 1) +
                                "</strong>"
                            )
                            .replace(
                              "{[TOTAL_QUESTIONS]}",
                              "<strong>" +
                                testData.questions.length +
                                "</strong>"
                            )
                        }}
                      />
                    ) : (
                      <span>
                        Question{" "}
                        <strong>{this.state.questionNumber + 1}</strong> /{" "}
                        <strong>{testData.questions.length}</strong>
                      </span>
                    )}
                  </p>
                ) : null}
              </Col>
            </Row>
            {this.props.dataLoaded ? (
              <Row className="mb-3">
                <Col>
                  {this.props.dataLoaded ? <hr /> : null}

                  {this.state.error !== null ? (
                    <Alert variant="danger" className="text-center">
                      {this.state.error}
                    </Alert>
                  ) : null}
                  <div
                    className="test-question"
                    dangerouslySetInnerHTML={getInstructions()}
                  />
                  <div
                    className="test-question"
                    dangerouslySetInnerHTML={getQuestionStem()}
                  />
                  {testData.questions[this.state.questionNumber].image !==
                  "" ? (
                    <div className="text-center">
                      <img
                        className={`img-responsive img-responsive${this.props.subTestId}`}
                        src={`https://s3.amazonaws.com/criteria-test-images/${
                          testData.questions[this.state.questionNumber].image
                        }`}
                        alt="diagram"
                      />
                      {isCLIKv2 ? (
                        imageWidth > 750 ? (
                          <div>
                            <Button
                              variant="link"
                              onClick={this.handleEnlarge}
                              className="my-3"
                            >
                              <FaExpandArrowsAlt /> Enlarge Image
                            </Button>
                          </div>
                        ) : null
                      ) : null}
                      {isCLIKv2 ? (
                        this.state.imageIsOpen ? (
                          <Lightbox
                            mainSrc={`https://s3.amazonaws.com/criteria-test-images/${
                              testData.questions[this.state.questionNumber]
                                .image
                            }`}
                            onCloseRequest={this.handleEnlargeClose}
                          />
                        ) : null
                      ) : null}
                    </div>
                  ) : null}
                  <fieldset className="mt-3">
                    <legend>Answer Options</legend>
                    {AnswerOptions}
                  </fieldset>
                </Col>
              </Row>
            ) : null}
            {this.props.dataLoaded ? (
              <NextButton
                handler={this.handleNext}
                buttonLabel={
                  this.props.testData.subTestText?.submit ??
                  translate("common", "submitAnswer", language) ??
                  "Submit Answer"
                }
                disableNextButton={this.state.disableNextButton}
              />
            ) : null}
            {this.state.showInterruptModal ? (
              <InterruptModal>
                <Row>
                  <Col className="text-center">
                    <h1
                      style={{
                        fontSize: "150px",
                        margin: "50px 0"
                      }}
                    >
                      <MdSignalWifiOff />
                    </h1>
                    <h3>
                      <strong>Test Paused</strong>
                    </h3>
                    {!this.state.didReconnectFail ? (
                      <div className="text-left">
                        <p>
                          There was an error while trying to save your answer.
                          Your timer will be paused until the test is resumed.
                        </p>
                        <p>Please wait while we try to reconnect.</p>
                      </div>
                    ) : (
                      <div className="text-left">
                        <p>
                          We were unable to reconnect. Please try again later or
                          from a different device.
                        </p>
                      </div>
                    )}
                    {!this.state.didReconnectFail ? (
                      <BarLoader height={6} width={150} color={"#428bca"} />
                    ) : (
                      <div className="text-center">
                        <Button
                          variant="success"
                          id="retry-connection-button"
                          onClick={this.handleRetryButton}
                        >
                          Retry
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </InterruptModal>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}
